import React, {Component} from 'react';
import {Link} from "react-router-dom";
import routes from "../routes";
import {Translate, withLocalize} from "react-localize-redux";
import headerTranslations from '../translations/header.json';
import LanguageToggle from "./LanguageToggle";
import {config} from "../config/config";

class HeaderComponent extends Component {
  constructor(props, context) {
    super(props, context);

    this.props.addTranslation(headerTranslations);
    this.setCustomActiveLanguage = this.setCustomActiveLanguage.bind(this);
  }

  setCustomActiveLanguage(languageCode) {
    config.persistLanguage(languageCode);
    this.props.setActiveLanguage(languageCode);
  }

  render() {
    return (
      <div>
        <section className="top-bar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-offset-3 col-lg-9">
                <div className="top-bar">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="welcome pull-left">
                        <p><span className="flaticon-arrows"/><Translate id="header.welcome_to"/>
                          &nbsp;Buffadini S.A.</p>
                      </div>
                      <div className="pull-right">
                        <LanguageToggle languages={this.props.languages}
                                        activeLanguage={this.props.activeLanguage}
                                        setActiveLanguage={this.setCustomActiveLanguage}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <header className="header-area hidden-xs">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="logo">
                  <Link to={routes.home}>
                    <img src="/images/logo.png" alt="Buffadini Logo"/>
                  </Link>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="header-contact-info">
                      <ul>
                        <li>
                          <div className="icon-holder">
                            <span className="flaticon-technology"/>
                          </div>
                          <div className="text-holder">
                            <h5><Translate id="header.contact_us"/></h5>
                            <h6>(+352) 40 32 42 - 1</h6>
                          </div>
                        </li>
                        <li>
                          <div className="icon-holder">
                            <span className="flaticon-pin"/>
                          </div>
                          <div className="text-holder">
                            <h5>65, rue des Bruyères</h5>
                            <h6>L-1274 Howald</h6>
                          </div>
                        </li>
                        <li>
                          <div className="icon-holder">
                            <span className="flaticon-agenda"/>
                          </div>
                          <div className="text-holder">
                            <h5><Translate id="global.monday_to_friday"/></h5>
                            <h6>08:00 - 17:00</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default withLocalize(HeaderComponent);



