import React, {Component} from 'react';
import {Markdown} from "../components/Markdown";
import {Link} from "react-router-dom";
import routes from "../routes";
import {Translate, withLocalize} from "react-localize-redux";
import legalTranslations from "../translations/legal.json";

class HistoryPage extends Component {

  constructor(props: P, context: any) {
    super(props, context);

    this.props.addTranslation(legalTranslations);
  }

  render() {
    const legaltext = this.legalTranslation[this.props.activeLanguage.code] || this.legalTranslation[this.props.defaultLanguage] || "";

    return (
      <div>

        <section className="breadcrumb-area">
          <div className="container text-center">
            <h1><Translate id="legal.legal"/></h1>
          </div>
        </section>

        <section className="breadcrumb-botton-area">
          <div className="container">
            <div className="left">
              <ul>
                <li><Link to={routes.home}><Translate id="navigation.homepage"/></Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true"/></li>
                <li className="active"><Translate id="legal.legal"/></li>
              </ul>
            </div>
          </div>
        </section>

        <section className="blog-area blog-single-area">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div className="single-blog-post" style={{marginBottom: 0}}>
                  <div className="text-holder">
                    <Markdown source={legaltext}/>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  legalTranslation = {
    fr: "#### Qui nous sommes\n" +
      "Buffadini S.A.\n" +
      "65, rue des Bruyères\n" +
      "L-1274 Howald\n" +
      "\n" +
      "#### Contact\n" +
      "Email: info \\[at\\] buffadini.lu\n" +
      "RCSL: B32305\n" +
      "\n" +
      "#### Règlement des différends\n" +
      "La Commission européenne fournit une plate-forme de résolution des litiges en ligne: https://ec.europa.eu/consumers/odr\n" +
      "Vous pouvez trouver notre adresse e-mail dans l'empreinte ci-dessus.\n" +
      "\n" +
      "#### Procédure de règlement des litiges devant un conseil d'arbitrage de la consommation\n" +
      "Nous ne sommes pas disposés ou obligés de participer à une procédure de résolution de litige devant un conseil d'arbitrage de consommation.\n" +
      "\n" +
      "#### Responsabilité pour le contenu\n" +
      "En tant que fournisseur de services, nous sommes responsables de notre propre contenu sur ces pages conformément aux lois générales. Cependant, en tant que fournisseur de services, nous ne sommes pas tenus de surveiller les informations de tiers transmises ou stockées ou d'enquêter sur les circonstances qui indiquent une activité illégale.\n" +
      "Les obligations de supprimer ou de bloquer l'utilisation des informations conformément aux lois générales restent inchangées. Cependant, la responsabilité à cet égard n'est possible qu'à partir du moment où nous prenons connaissance d'une violation de la loi spécifique. Dès que nous aurons connaissance de telles violations, nous supprimerons ce contenu immédiatement.\n" +
      "\n" +
      "#### Responsabilité pour les liens\n" +
      "Notre offre contient des liens vers des sites Web externes de tiers, sur lesquels nous n'avons aucune influence sur le contenu. Pour cette raison, nous ne pouvons accepter aucune responsabilité pour ce contenu externe. Le fournisseur ou l'exploitant respectif des pages est toujours responsable du contenu des pages liées. Les pages liées ont été vérifiées pour d'éventuelles violations de la loi au moment de la liaison. Aucun contenu illégal n'était discernable au moment de la création du lien.\n" +
      "Un contrôle permanent du contenu des pages liées n'est pas raisonnable sans preuve concrète d'une infraction. Dès que nous aurons connaissance de violations de la loi, nous supprimerons ces liens immédiatement.\n" +
      "\n" +
      "#### Droits d'auteur\n" +
      "Le contenu et les travaux de ces pages créés par l'exploitant du site sont soumis au droit d'auteur luxembourgeois. La duplication, l'édition, la distribution et tout type d'utilisation au-delà des limites de la loi sur le droit d'auteur nécessitent le consentement écrit de l'auteur ou du créateur respectif. Les téléchargements et copies de cette page ne sont autorisés que pour un usage privé et non commercial.\n" +
      "Dans la mesure où le contenu de cette page n'a pas été créé par l'opérateur, les droits d'auteur de tiers sont respectés. En particulier, les contenus de tiers sont signalés comme tels. Si vous constatez néanmoins une violation du droit d'auteur, veuillez nous en informer. Dès que nous aurons connaissance de violations de la loi, nous supprimerons ce contenu immédiatement.",
    de: "#### Wer wir sind\n" +
      "Buffadini S.A.\n" +
      "65, rue des Bruyères\n" +
      "L-1274 Howald\n" +
      "\n" +
      "#### Kontakt\n" +
      "Email: info \\[at\\] buffadini.lu\n" +
      "RCSL: B32305\n" +
      "\n" +
      "#### EU-Streitschlichtung\n" +
      "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr\n" +
      "Unsere E-Mail-Adresse finden Sie oben im Impressum.\n" +
      "\n" +
      "#### Verbraucherstreitbeilegung/Universalschlichtungsstelle\n" +
      "Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen." +
      "\n" +
      "#### Haftung für Inhalte\n" +
      "Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.\n" +
      "Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.\n" +
      "\n" +
      "#### Haftung für Links\n" +
      "Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.\n" +
      "Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.\n" +
      "\n" +
      "#### Urheberrecht\n" +
      "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.\n" +
      "Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.",
    en: "#### Who we are\n" +
      "Buffadini S.A.\n" +
      "65, rue des Bruyères\n" +
      "L-1274 Howald\n" +
      "\n" +
      "#### Contact\n" +
      "Email: info \\[at\\] buffadini.lu\n" +
      "RCSL: B32305\n" +
      "\n" +
      "#### Dispute settlement\n" +
      "The European Commission provides a platform for online dispute resolution (ODR): https://ec.europa.eu/consumers/odr\n" +
      "You can find our email address in the imprint above.\n" +
      "\n" +
      "#### Dispute resolution proceedings in front of a consumer arbitration board\n" +
      "We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.\n" +
      "\n" +
      "#### Liability for content\n" +
      "As a service provider, we are responsible for our own content on these pages in accordance with general laws. However, as a service provider, we are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity.\n" +
      "Obligations to remove or block the use of information according to general laws remain unaffected. However, liability in this regard is only possible from the time we become aware of a specific legal violation. As soon as we become aware of such violations, we will remove this content immediately.\n" +
      "\n" +
      "#### Liability for links\n" +
      "Our offer contains links to external websites of third parties, the content of which we have no influence on. For this reason, we cannot accept any liability for this external content. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. No illegal content was discernible at the time the link was created.\n" +
      "A permanent control of the content of the linked pages is not reasonable without concrete evidence of an infringement. As soon as we become aware of legal violations, we will remove such links immediately.\n" +
      "\n" +
      "#### Copyright\n" +
      "The content and works on these pages created by the site operator are subject to Luxembourgish copyright law. Duplication, editing, distribution and any kind of use beyond the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this page are only permitted for private, non-commercial use.\n" +
      "As far as the content on this page was not created by the operator, the copyrights of third parties are respected. In particular contents of third parties are marked as such. Should you nevertheless become aware of a copyright infringement, please let us know. As soon as we become aware of legal violations, we will remove such content immediately."
  }
}

export default withLocalize(HistoryPage);
